<template>
  <div class="wrapper">
    <panel title="Update Avatar">
      <div class="profile-info">
        <avatar :profile="user" allow-upload />
      </div>
    </panel>

    <panel
      title="Update Password"
      collapsable>
      <ms-input
        v-model="password.password"
        :validator="$v.password.password"
        :options="{ 'show-password': true }"
        placeholder="Enter new password" />
      <ms-input
        v-model="password.repeatPassword"
        :validator="$v.password.repeatPassword"
        :options="{ 'show-password': true }"
        placeholder="Repeat password"
        @enter="updatePassword()" />

      <div class="flex-center mt-15">
        <el-button
          type="primary"
          icon="el-icon-lock"
          :loading="loading.password"
          @click="updatePassword()">
          Update Password
        </el-button>
      </div>
    </panel>
  </div>
</template>

<script>
import { passwordValidation } from '@/components/common/validations'
import { required, minLength, sameAs } from 'vuelidate/lib/validators'

import Avatar from '@/components/common/ui/Avatar'

export default {
  components: {
    Avatar
  },

  data () {
    return {
      password: {
        password: null,
        repeatPassword: null
      },

      loading: {
        password: false
      }
    }
  },

  computed: {
    user () {
      return this.$store.state.app.user
    }
  },

  created () {
    this.setPageInfo()
  },

  methods: {
    async updatePassword () {
      this.$v.password.$touch()
      if (this.$v.password.$invalid) return

      this.loading.password = true
      try {
        await this.$store.dispatch('users/action', {
          id: this.user.id,
          action: 'SetPassword',
          payload: {
            password: this.password.password
          }
        })
        this.password = {
          password: null,
          repeatPassword: null
        }
        this.$v.password.$reset()
        this.$message.success('Password updated successfully.')
      } catch (error) {
        this.$message.error('There was an error. Please try again.')
      } finally {
        this.loading.password = false
      }
    },

    setPageInfo () {
      this.$store.commit('page/setPageInfo', {
        title: 'Profile Settings',
        back: { name: 'Profile' }
      })
    }
  },

  validations () {
    const rules = {
      password: {
        password: { required, passwordValidation, minLength: minLength(6) },
        repeatPassword: { required, sameAs: sameAs('password') }
      }
    }

    return rules
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
  .profile-info {
    display: flex;
    align-items: center;
    flex-direction: column;
    .name {
      margin-top: 20px;
      font-size: 16px;
      font-weight: bold;
      color: #091520;
    }
    .email {
      display: block;
      font-size: 16px;
      color: #5a738a;
      margin-top: 3px;
    }
  }
}
</style>
